










































import { Component, Vue } from "vue-property-decorator";

import { VCAImportFehlerModel } from "@/models/VCAImportFehlerModel";
import { catVue } from "@/util/logging";

// eslint-disable-next-line

@Component({
  components: {
    VcaFehlerFixer: () =>
      import(
        /* webpackChunkName: "vca-fehler-fixer" */ "@/components/fehlerlisten/VCAFehlerFixer.vue"
      ),
  },
})
export default class VCAFehlerListe extends Vue {
  //

  value = 0;

  loading = true;

  liste = [] as VCAImportFehlerModel[];
  headers = [
    { text: "Zeile", align: "end", value: "lineNr" },
    { text: "Datei", align: "start", value: "filename" },
    { text: "Objekt", align: "start", value: "objekt" },
    { text: "Jahr", align: "end", value: "jahr" },
    { text: "Ausgabe", align: "end", value: "heft" },
    { text: "Menge", align: "end", value: "menge" },
    { text: "Kommentar", align: "start", value: "kommentar" },
    { text: "", align: "start", value: "data-table-expand" },
  ];

  created(): void {
    catVue.info(`${this.$options.name}:: CREATED ... ${this.value}`);
  }

  mounted(): void {
    catVue.info(`${this.$options.name}:: MOUNTED ... ${this.value}`);
    VCAImportFehlerModel.fetch()
      .then((liste) => {
        this.liste = liste;
        this.loading = false;
      })
      .catch((error) => {
        catVue.error(`${this.$options.name}:: ERROR ... ${this.value}`, error);
      });
  }
}

Vue.component("vca-fehler-liste", VCAFehlerListe);
//  store.commit("changeAppLoadingState", true, {root: true});
//  window.setTimeout( () =>   store.commit("changeAppLoadingState", false, {root: true}), 10000)
