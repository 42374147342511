var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"loading":_vm.loading,"multi-sort":"","headers":_vm.headers,"items":_vm.liste,"item-key":"uid","show-expand":"","footer-props":{
         showFirstLastPage: true,
         'items-per-page-text': 'Zeilen pro Seite',
      }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"detail-cell",attrs:{"colspan":headers.length - 3}},[_c('vca-fehler-fixer',{attrs:{"item":item}})],1),_c('td')]}},{key:"item.objekt",fn:function(ref){
      var item = ref.item;
return [(item.vcaID !== null)?[_c('small',[_vm._v("VCA-ID")]),_vm._v(" "),_c('b',[_vm._v(_vm._s(item.vcaID))]),(item.titel != '')?_c('span',[_vm._v(": "+_vm._s(item.titel))]):_vm._e()]:[_c('small',[_vm._v("VDZ-Nummer")]),_vm._v(" "),_c('b',[_vm._v(_vm._s(item.vdzNr))]),_vm._v(": "+_vm._s(item.titel)+" ")]]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }